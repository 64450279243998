@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/palette';

.VerifyEmail {
  .loading-wrapper {
    position: absolute;
    inset: 0;
    z-index: 999;
    background: #fafafd;
    opacity: 0.8;
    @include flexbox(center, center);
    .dots-circle-spinner {
      font-size: 42px;
    }
  }
  .code-is-expired {
    @include white-container(448px);
    @include flexbox(center, center);
    flex-direction: column;
    gap: 20px;
    margin: 64px auto;
    .top-icon {
      width: 21px;
      height: 18px;
    }
    h4 {
      @include headline-medium;
      text-align: center;
      margin: 0;
    }
    .send-new-link {
      @include blue-contained-button;
      margin-top: 8px;
      width: 100%;
    }
    .login-link {
      @include button-to-link;
      color: $InitioBlue;
      padding: 0;
    }
  }
}
