@import './styles/mixins';
@import './styles/palette';
@import './styles/typography';

// Global style alterations

// Text-fields
.MuiFormControl-root {
  // Labels
  .MuiFormLabel-root {
    &.Mui-focused {
      color: $Primary50;
    }
    &.Mui-error {
      color: $LightOnSurfaceVariant;
      &.MuiInputLabel-shrink {
        color: $LightError;
      }
    }
  }

  // Input
  .MuiInputBase-root {
    border-radius: 12px;
    .MuiOutlinedInput-notchedOutline {
      border-color: $DarkOutline;
    }
    &:hover {
      &:not(.Mui-disabled) {
        .MuiOutlinedInput-notchedOutline {
          box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
        }
      }
      &:not(.Mui-disabled, .Mui-focused) {
        .MuiOutlinedInput-notchedOutline {
          border-color: $DarkOutline;
        }
      }
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $InitioBlue;
      }
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $LightError;
      }
    }
  }

  // Helper text
  .MuiFormHelperText-root {
    @include body-small;
    height: 18px;
    &.Mui-error {
      @include flexbox;
      gap: 4px;
      color: $LightError;
      .MuiSvgIcon-root {
        margin-top: 3px;
        height: 14px;
        width: 14px;
      }
    }
  }
}

//Snackbar
.MuiSnackbar-root {
  .MuiPaper-root {
    margin-top: 48px;
    border-radius: 8px;
    background-color: #313033;
    .MuiSnackbarContent-message {
      @include flexbox(center, center);
      gap: 12px;
      @include body-medium;
      color: #ffffff;
      .MuiButtonBase-root {
        @include text-only-button-dark;
      }
    }
  }
}

// Loading spinner
@include spinning-dots;

.MuiButtonBase-root {
  &.loading {
    pointer-events: none;
  }
  .dots-circle-spinner {
    margin-right: 8px;
  }
}

.loading-dialog {
  .MuiPaper-root {
    overflow: hidden;
    padding: 24px;
    opacity: 0.8;
    .loading-spinner {
      $spinnerSize: 62px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
  }
}
