@import './palette';
@import './typography';

@mixin white-container($containerWidth) {
  background-color: $Container2;
  width: $containerWidth;
  box-shadow: 0 8px 16px rgb(0 49 93 / 20%);
  border-radius: 28px;
  padding: 40px 48px;
  @media only screen and (width <= 450px) {
    width: 350px;
    .MuiInputBase-root {
      width: 280px !important;
    }
  }
}

@mixin flexbox($justifyContent: flex-start, $alignItems: flex-start) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin button-to-link {
  border: none;
  text-transform: none;
  text-decoration: underline;
  background: none !important;
  padding: 0;
  color: $InitioBlue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:active,
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &:disabled {
    color: inherit;
  }
}

@mixin default-buttons-style {
  svg {
    margin-right: 10px;
  }
  @include label-large;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  &:focus-visible {
    outline: 2px solid $InitioBlue;
  }
  &:active {
    outline: none;
  }
}

@mixin blue-contained-button {
  @include default-buttons-style;
  background-color: $Primary50;
  color: #fff;
  &:hover {
    background-color: $Primary30;
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    background-color: $Primary30;
  }
  &:active {
    background-color: $Primary30;
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: #fff;
    background-color: $Outline;
  }
}

@mixin tonal-contained-button {
  @include default-buttons-style;
  background-color: #d0d9ee;
  color: $BlueSteel;
  &:hover {
    background-color: #c3d4ee;
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    background-color: #bbcbe4;
  }
  &:active {
    background-color: #bac2d5;
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: #303d5c;
    opacity: 0.8;
    background-color: $Outline;
  }
}

@mixin light-filled-button {
  @include default-buttons-style;
  background-color: $LightSurface;
  color: $Primary40;
  box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  &:hover {
    background: rgb(0 72 132 / 8%);
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    background: rgb(0 72 132 / 12%);
  }
  &:active {
    background: rgb(0 28 57 / 10%);
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
    background-color: $Outline;
  }
}

@mixin outlined-button {
  @include default-buttons-style;
  background: none;
  border: 1px solid #315880;
  color: $Primary40;
  &:hover {
    background-color: rgb(0 72 132 / 8%);
  }
  &:focus-visible {
    background-color: rgb(0 72 132 / 8%);
    border: 1px solid $InitioBlue;
    outline: 1px solid $InitioBlue;
  }
  &:active {
    background-color: rgb(0 28 57 / 10%);
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
    border: 1px solid $Outline;
  }
}

@mixin text-only-button($textDecoration: none) {
  @include default-buttons-style;
  text-decoration: $textDecoration;
  background: none;
  border: none;
  color: $Primary40;
  @if $textDecoration != none {
    color: $InitioBlue;
  }
  &:hover {
    background-color: rgb(0 72 132 / 8%);
    text-decoration: $textDecoration;
  }
  &:focus-visible {
    background-color: rgb(0 72 132 / 8%);
  }
  &:active {
    background-color: rgb(0 28 57 / 10%);
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
  }
}

@mixin text-only-button-dark($textDecoration: none) {
  @include default-buttons-style;
  text-decoration: $textDecoration;
  background: none;
  border: none;
  color: $Primary80;
  &:hover {
    background-color: rgb(158 202 255 / 8%);
  }
  &:focus-visible {
    background-color: rgb(158 202 255 / 8%);
  }
  &:active {
    background-color: rgb(158 202 255 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
  }
}

@mixin spin-infinitely($duration: 4000ms) {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation-name: spin;
  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@mixin spinning-dots {
  @-webkit-keyframes dots-circle-rotation {
    100% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
    87.5% {
      box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em,
        -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em,
        -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em,
        1.41421356em -1.41421356em 0 0em;
    }
    75% {
      box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em,
        -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em,
        -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em,
        1.41421356em -1.41421356em 0 -0.4375em;
    }
    62.5% {
      box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em,
        -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em,
        -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em,
        1.41421356em -1.41421356em 0 -0.375em;
    }
    50% {
      box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em,
        -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em,
        -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em,
        1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em,
        -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em,
        -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em,
        1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em,
        -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em,
        -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em,
        1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em,
        -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em,
        -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em,
        1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
  }

  @keyframes dots-circle-rotation {
    100% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
    87.5% {
      box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em,
        -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em,
        -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em,
        1.41421356em -1.41421356em 0 0em;
    }
    75% {
      box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em,
        -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em,
        -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em,
        1.41421356em -1.41421356em 0 -0.4375em;
    }
    62.5% {
      box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em,
        -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em,
        -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em,
        1.41421356em -1.41421356em 0 -0.375em;
    }
    50% {
      box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em,
        -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em,
        -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em,
        1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em,
        -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em,
        -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em,
        1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em,
        -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em,
        -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em,
        1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em,
        -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em,
        -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em,
        1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
  }

  .dots-circle-spinner {
    display: inline-block;
    height: 1em;
    width: 1em;
    line-height: 1;
    vertical-align: middle;
    border-radius: 1em;
    transition: all 150ms linear 0s;
    transform: scale(0);
    opacity: 0;
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
      -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
      -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
      1.41421356em -1.41421356em 0 -0.0625em;
    transform: scale(0.25);
    opacity: 1;
    -webkit-animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation;
    animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation;
  }
}
